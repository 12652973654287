import React from "react"
import { Container, Grid, Heading } from "@chakra-ui/react"

import { AnalyticProps, withSection } from "@app/hoc/Section"
import { useImage } from "@app/hooks/useImage"
import { useRoutes } from "@app/hooks/useRoutes"
import FeaturedCollectionsItem from "./FeaturedCollectionsItem"

type Props = AnalyticProps & {
  link: any
  image: any
  theme: any
  bgColor: any
  title: string
  innerRef: any
  inView: boolean
  collections: Array<any>
  handleTrackingClick: () => void
}

const FeaturedCollections: React.FC<Props> = ({ title, bgColor, theme, collections, inView, innerRef, handleTrackingClick, tag }) => {
  const { getGatsbyImage } = useImage()
  const { urlResolver } = useRoutes()

  const items = collections?.map(item => ({
    ...item,
    key: item?._id,
    image: getGatsbyImage(item?.image),
    link: urlResolver(item),
  }))

  return (
    <Container
      ref={innerRef}
      as="section"
      maxW="11xl"
      bgColor={bgColor?.hex}
      px={[4, 8]}
      py={[8, 16]}
      bg={theme === "Dark" ? "brand.light" : "white"}
      textAlign="center"
    >
      <Heading as={tag} size="xl" mb={[4, 8]}>
        {title}
      </Heading>
      <Grid templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]} gap={4}>
        {inView && items?.map(item => <FeaturedCollectionsItem key={item?.key} item={item} handleTrackingClick={handleTrackingClick} />)}
      </Grid>
    </Container>
  )
}

export default React.memo(withSection(FeaturedCollections))
