import React from "react"
import { GridItem, Image, Heading } from "@chakra-ui/react"

import Link from "@app/components/Link"

type Props = {
  item: any
  handleTrackingClick: () => void
}

const FeaturedCollectionsItem: React.FC<Props> = ({ item, handleTrackingClick }) => (
  <GridItem as="article">
    <Link title={item?.title} to={item?.link?.url} onClick={handleTrackingClick}>
      <Heading as="p" size="lg" mb="1">
        {item?.title}
      </Heading>
      {item?.image && <Image {...item.image} />}
    </Link>
  </GridItem>
)

export default React.memo(FeaturedCollectionsItem)
